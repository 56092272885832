let accessToken = null
let refreshToken = null

function getAccessToken () {
  return accessToken
}

function getRefreshToken () {
  return refreshToken
}

function get () {
  return {
    accessToken: getAccessToken(),
    refreshToken: getRefreshToken(),
  }
}

function setAccessToken (token) {
  accessToken = token ?? null
}

function setRefreshToken (token) {
  refreshToken = token ?? null
}

function set (tokens = {}) {
  if (tokens.accessToken !== undefined) {
    setAccessToken(tokens.accessToken)
  }
  if (tokens.refreshToken !== undefined) {
    setRefreshToken(tokens.refreshToken)
  }
}

export default {
  get,
  set,
  getAccessToken,
  setAccessToken,
  getRefreshToken,
  setRefreshToken,
}
