import VueSocketIO from 'vue-socket.io'
import socket from 'src/services/socket'

export default function SocketPlugin ({ Vue, store }) {
  Vue.use(new VueSocketIO({
    debug: process.env.NODE_ENV === 'development',
    connection: socket,
    vuex: {
      store,
      actionPrefix: 'SOCKET_',
    },
  }))
}
