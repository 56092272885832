import i18n from 'src/plugins/i18n'
import axios from 'src/services/axios'
import CrudRepository from './crud-repository'

const IGNORED_ERROR_LIST = [
  'MultiProjectSessionPlayerChangeStatusError',
  'MultiTaskSessionPlayerChangeStateError',
  'NetworkDiagramSessionPlayerChangeStateError',
  'OnTimeSessionPlayerChangeStateError',
  'NetworkDiagramSessionPlayerNextInstructionError',
]

function getErrorCode (error) {
  if (error?.message === 'Network Error') {
    return 'NetworkError'
  }

  if (IGNORED_ERROR_LIST.includes(error?.response?.data?.error)) {
    return null
  }

  return error?.response?.data?.error ?? 'UnknownError'
}

export function parseErrorMessage (error) {
  const errorCode = getErrorCode(error)
  return errorCode && i18n.t(`error.api.${errorCode}`)
}

export function createCrudRepository (resource) {
  return {
    getList: (...args) => CrudRepository.getList(resource, ...args),
    get: (...args) => CrudRepository.get(resource, ...args),
    post: (...args) => CrudRepository.post(resource, ...args),
    patch: (...args) => CrudRepository.patch(resource, ...args),
    delete: (...args) => CrudRepository.delete(resource, ...args),
  }
}

export function getApiUrl (path) {
  const base = axios.defaults.baseURL.replace(/\/$/, '')
  return path !== undefined ? `${base}/${path}` : base
}

export default {
  parseErrorMessage,
  createCrudRepository,
  getApiUrl,
}
