import Event from './event'
import Queue from './queue'
import Token from './token'

const failedQueue = new Queue()
let hasRefreshInProgress = false

function isExpiredToken (error) {
  return !error.config.isRetryRequest
    && error.response.status === 401
    && error.response?.data?.error === 'TokenExpiredError'
}

function isInvalidToken (error) {
  return !error.config.isRetryRequest
    && error.response.status === 401
    && error.response?.data?.error === 'TokenNotExistError'
}

function retryFailedRequest ({ axios, originalRequest }) {
  const accessToken = Token.getAccessToken()
  originalRequest.headers.Authorization = `Bearer ${accessToken}`
  return axios(originalRequest)
}

async function waitRefreshToken ({ axios, originalRequest }) {
  try {
    await failedQueue.wait()
    return retryFailedRequest({ axios, originalRequest })
  }
  catch (error) {
    return Promise.reject(error)
  }
}

function onTokenRefreshSuccessful ({ accessToken, refreshToken }) {
  Token.set({ accessToken, refreshToken })
  Event.dispatchEvent('tokenRefreshSuccessful', { accessToken, refreshToken })
  failedQueue.resolve({ accessToken, refreshToken })
}

function onTokenRefreshFailed ({ error }) {
  Token.set({ accessToken: null, refreshToken: null })
  Event.dispatchEvent('tokenRefreshFailed')
  failedQueue.reject(error)
}

async function sendRefreshToken ({ axios }) {
  try {
    hasRefreshInProgress = true
    const refreshToken = Token.getRefreshToken()
    const response = await axios.post('trainingSession/refreshToken', { refreshToken })
    onTokenRefreshSuccessful(response.data)
  }
  catch (error) {
    onTokenRefreshFailed({ error })
  }
  finally {
    hasRefreshInProgress = false
  }
}

async function needRefreshToken ({ axios, originalRequest }) {
  try {
    await sendRefreshToken({ axios })
    return retryFailedRequest({ axios, originalRequest })
  }
  catch (error) {
    return Promise.reject(error)
  }
}

function onFulfilled ({ axiosResponse }) {
  return axiosResponse
}

function onRejected ({ axios, error }) {
  if (isExpiredToken(error)) {
    const originalRequest = { ...error.config, isRetryRequest: true }
    return hasRefreshInProgress
      ? waitRefreshToken({ axios, originalRequest })
      : needRefreshToken({ axios, originalRequest })
  }

  if (isInvalidToken(error)) {
    Event.dispatchEvent('tokenRefreshFailed')
  }

  return Promise.reject(error)
}

export default function AddRequestInterceptor ({ axios }) {
  axios.interceptors.response.use(
    axiosResponse => onFulfilled({ axios, axiosResponse }),
    error => onRejected({ axios, error }),
  )
}
