export function registerStoreModule ({ router, store }) {
  store.registerModule('router', {
    namespaced: true,
    actions: {
      push: (context, ...args) => router.push(...args),
      replace: (context, ...args) => router.replace(...args),
      back: (context, ...args) => router.back(...args),
      forward: (context, ...args) => router.forward(...args),
      go: (context, ...args) => router.go(...args),
    },
  })
}
