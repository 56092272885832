export default {
  ready: 'ready',
  startGame: 'startGame',
  startProject: 'startProject',
  doesntStartProject: 'doesntStartProject',
  startNewWeek: 'startNewWeek',
  claimResources: 'claimResources',
  askForResources: 'askForResources',
  acceptBlueOverCapacity: 'acceptBlueOverCapacity',
  declineBlueOverCapacity: 'declineBlueOverCapacity',
  updateGantt: 'updateGantt',
}
