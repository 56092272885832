export function showNotification ({ commit }, { success, message }) {
  if (message) {
    commit('SHOW_NOTIFICATION', { success, message })
  }
}

export function showSuccessNotification ({ commit }, message) {
  if (message) {
    commit('SHOW_NOTIFICATION', { success: true, message })
  }
}

export function showErrorNotification ({ commit }, message) {
  if (message) {
    commit('SHOW_NOTIFICATION', { success: false, message })
  }
}

export function hideNotification ({ commit }) {
  commit('HIDE_NOTIFICATION')
}

export function setTitle ({ commit }, title) {
  commit('SET_TITLE', title)
}
