import Repositories from 'src/repositories'

export async function loadSession ({ commit }, sessionId) {
  commit('SET_SESSION', await Repositories.session.get(sessionId))
}

export async function reloadSession ({ commit, getters }) {
  if (getters.sessionId) {
    commit('SET_SESSION', await Repositories.session.get(getters.sessionId))
  }
}

export function resetState ({ commit }) {
  commit('RESET_STATE')
}
