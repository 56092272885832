import RepositoryService from 'src/services/repository'

const resource = 'networkDiagram/instruction'

export default {
  async getList () {
    const { instructionList, companyId } = await RepositoryService.crud.getList(resource)
    const baseUrl = RepositoryService.utils.getApiUrl(`${resource}/${companyId}`)
    return instructionList.map(instruction => ({
      ...instruction,
      src: `${baseUrl}/${instruction.src}`,
    }))
  },
}
