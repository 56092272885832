export default [
  {
    path: '/network-diagram/:gameId',
    alias: [
      '/networkDiagramWithoutInstructions/:gameId',
      '/networkDiagramWithInstructions/:gameId',
    ],
    component: () => import('src/layouts/DefaultLayout'),
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        name: 'NetworkDiagram',
        component: () => import('./containers/NetworkDiagramContainer'),
        props: true,
        children: [
          {
            path: 'welcome',
            name: 'NetworkDiagramWelcome',
            component: () => import('./containers/WelcomeContainer'),
          },
          {
            path: 'playing',
            name: 'NetworkDiagramPlaying',
            component: () => import('./containers/PlayingContainer'),
          },
          {
            path: 'finished',
            name: 'NetworkDiagramFinished',
            component: () => import('./containers/FinishedContainer'),
          },
        ],
      },
    ],
  },
]
