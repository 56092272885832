export default {
  general: {
    name: 'Management de projet par le FLUX',
    yes: 'Oui',
    no: 'Non',
    add: 'Ajouter',
    update: 'Modifier',
    close: 'Fermer',
    cancel: 'Annuler',
    create: 'Créer',
    remove: 'Supprimer',
    confirmation: 'Confirmation',
    search: 'Recherche',
    language: 'Langue',
    validate: 'Valider',
    connect: 'Connexion',
    disconnect: 'Déconnexion',
    welcome: 'Bienvenue {name}',
    loading: 'Chargement en cours... Veuillez patienter',
    loadingShort: 'Chargement...',
    empty: 'Vide',
    actions: 'Actions',
    start: 'Démarrer',
    previous: 'Précédent',
    next: 'Suivant',
    finish: 'Terminer',
  },
  date: {
    date: 'dd/MM/yyyy',
    time: 'HH:mm',
    dateAndTime: 'dd/MM/yyyy à HH:mm',
  },
  language: {
    french: 'Français',
    english: 'Anglais',
  },
  menu: {},
  title: {
    login: 'Management de projet par le FLUX',
    home: 'Accueil',
  },
  color: {
    red: 'Rouge',
    pink: 'Rose',
    purple: 'Violet',
    'deep-purple': 'Violet foncé',
    indigo: 'Indigo',
    blue: 'Bleu',
    'light-blue': 'Bleu clair',
    cyan: 'Cyan',
    teal: 'Teal',
    green: 'Vert',
    'light-green': 'Vert clair',
    lime: 'Citron',
    yellow: 'Jaune',
    amber: 'Ambré',
    orange: 'Orange',
    'deep-orange': 'Orange foncé',
    brown: 'Marron',
    'blue-grey': 'Bleu gris',
    grey: 'Gris',
  },
  error: {
    form: {
      required: 'Le champ est requis',
      minLength: 'Le champ doit contenir au moins {length} caractères',
      maxLength: 'Le champ ne doit pas contenir plus de {length} caractères',
      exactLength: 'Le champ doit faire exactement {length} caractères',
      noSpaces: 'Le champ ne doit pas contenir d\'espace',
      noEqualPassword: 'Les mots de passe ne sont pas identiques',
      userExist: 'L\'identifiant existe déjà dans votre organisation',
      alpha: 'Le champ ne peut contenir que des lettres',
      alphanumeric: 'Le champ ne peut contenir que des lettres et des chiffres',
      greater: 'Le nombre doit être supérieur à {number}',
      lower: 'Le nombre doit être inférieur à {number}',
      email: 'L\'adresse e-mail n\'est pas valide',
    },
    api: {
      TrainingSessionClosedError: 'Impossible de se connecter. La session est clôturée.',
      TrainingSessionNotFoundError: 'Impossible de se connecter. La session n\'existe pas.',
      TrainingSessionNotOpenError: 'Impossible de se connecter. La session n\'est pas ouverte.',
      TrainingSessionPlayerCodeNotFoundError: 'Impossible de se connecter. Le code participant n\'existe pas.',
      'Not Found': 'Une erreur inconnue est survenue. (404)',
      UnknownError: 'Une erreur inconnue est survenue.',
    },
  },
  login: {
    fields: {
      sessionCode: 'Code session',
      playerCode: 'Code participant',
      playerName: 'Nom Prénom',
      playerEmail: 'E-mail',
    },
    formrules: {
      playerCodeFormat: 'Le champ doit être au format A00',
    },
    message: {
      sessionClosed: 'Vous avez été déconnecté(e) suite à la fermeture de la session.',
      logoutConfirm: 'Voulez-vous vraiment vous déconnecter de la session de jeu ?',
    },
  },
  home: {
    title: 'Session : {sessionCode} - Participant : {playerName}',
    game: {
      title: {
        multiProjectStandard: 'Multi-projets Standard',
        multiProjectCcpm: 'Multi-projets Flux',
        multiTaskParallel: 'Multitâche',
        multiTaskSequential: 'Monotâche',
        networkDiagramWithoutInstructions: 'Gamme projet sans les instructions',
        networkDiagramWithInstructions: 'Gamme projet avec les instructions',
        onTime: 'Estimation du temps',
        criticalChain: 'Chaîne critique',
      },
      status: {
        closed: 'Verrouillé',
        opened: 'Accéder',
        finished: 'Terminé',
      },
    },
  },
  notification: {
    pause: 'Les jeux ont été mis en pause',
    play: 'Les jeux sont sortis du mode pause',
    gameOpened: 'Un nouveau jeu a été déverrouillé',
    gameFinished: 'Un jeu vient de se terminer',
    socket: {
      disconnect: 'La connexion au serveur a été perdue',
      reconnect: 'La connexion au serveur a été rétablie',
    },
  },
  multiproject: {
    common: {
      managementProjectTitle: 'Pilotage du projet {projectName}',
      managementStartedProjectTitle: 'Pilotage du projet démarré en semaine {week}',
      weekStartReal: 'Début du projet',
      weekEndPlanned: 'Estimation de fin du projet',
      week: 'Semaine',
      currentWeek: 'Semaine en cours : {week}',
      weekEndPlannedChanged: 'La date d\'estimation de fin du projet a bien été modifiée',
    },
    welcome: {
      title: 'Bienvenue',
      projectNameText: 'Vous avez été désigné(e) chef du projet {projectName}',
      projectStartText: 'Il est prévu qu\'il débute en semaine {weekStartPlanned}',
      readyButton: 'Je suis prêt',
      startButton: 'Lancer le jeu',
      waitingOthers: 'Veuillez attendre les autres participants {readyPlayers}/{totalPlayers}',
      error: 'Une erreur s\'est produite lors du passage au ',
    },
    start: {
      title: {
        gantt: 'Gantt',
        startPlanned: 'Début du projet prévu en semaine {week}',
      },
      cantStartProject: {
        title: 'Vous ne pouvez pas lancer votre projet pour le moment !',
        subtitle: 'Veuillez patienter',
        icon: 'mdi-close-octagon',
        color: 'blue-grey',
      },
      mustStartProject: {
        title: 'Nous sommes en semaine {week} !',
        subtitle: 'Vous devez lancer votre projet',
        icon: 'mdi-information-outline',
        color: 'success',
      },
      canStartProjectBlueReady: {
        title: 'La ressource "{resource}" est disponible !',
        subtitle: 'Souhaitez-vous démarrer le projet maintenant ?',
        icon: 'mdi-information-outline',
        color: 'info',
      },
      startProject: 'Lancer le projet',
      doesntStartProject: 'Refuser',
      ccpm: {
        title: 'Lancement du projet',
        mustStartProject: {
          title: 'La ressource critique est disponible !',
          subtitle: 'C\'est le moment de lancer votre projet.',
          icon: 'mdi-information-outline',
          color: 'success',
        },
        cantStartProject: {
          title: 'Vous ne pouvez pas lancer votre projet pour le moment !',
          subtitle: 'Veuillez patienter...',
          icon: 'mdi-close-octagon',
          color: 'blue-grey',
        },
      },
    },
    resourcesSelection: {
      title: 'Réclamation des ressources nécessaires',
      confirmButton: 'Valider les ressources',
      errorTitle: 'Erreur dans vos réclamations',
      fixSelectionButton: 'Modifier ma séléction',
      resourceMissing: 'Vous avez oublié de réclamer une ressource nécessaire, veuillez réclamer toutes les ressources nécessaires',
      resourceUnnecessary: 'Vous avez réclamé des ressources qui ne sont pas nécessaires, veuillez ne réclamer que les ressources dont vous avez besoin',
    },
    resourcesWait: {
      title: 'Réception de la capacité des ressources',
      message: 'Veuillez patientez le temps que tous les chefs de projet aient réclamé leurs ressources.',
    },
    getCapacity: {
      title: 'Réception de la capacité des ressources',
      button: 'Obtenir la capacité allouée aux ressources réclamées',
    },
    blueOverCapacity: {
      title: 'Il reste {capacity} de capacité à la ressource "{resource}" cette semaine',
      subtitle: 'Souhaitez-vous l\'utiliser et lancer votre projet ?',
      icon: 'mdi-help',
      color: 'info',
      acceptBlueOverCapacity: 'Lancer le projet',
      declineBlueOverCapacity: 'Refuser',
    },
    capacityReceived: {
      title: 'Réception de la capacité des ressources',
      button: 'Mettre à jour le gantt',
      noCapacity: 'Aucune capacité n\'était disponible cette semaine.',
      message: {
        firstPart: 'La ressource "{name}" a avancée de ',
        lastPart: ' sur sa tâche',
      },
    },
    mustChangeEndDate: {
      title: 'Mise à jour de la date de fin',
      message: 'Vous devez mettre à jour l\'estimation de la date de fin de votre projet !',
    },
    startNewWeek: {
      title: 'Début d\'une nouvelle semaine',
      button: 'Commencer la nouvelle semaine',
    },
    waitNewWeek: {
      title: 'Début d\'une nouvelle semaine',
      message: 'Veuillez patienter le temps que les autres chefs de projet aient mis leurs informations à jour',
    },
    projectFinished: {
      done: '| Terminé en {duration} semaine | Terminé en {duration} semaines',
      changes: 'sans changement de date de fin. | avec {changes} changement de date de fin. | avec {changes} changements de date de fin.',
      message: 'Vous avez fini le projet !',
    },
    waitingCriticalResource: {
      title: 'Avant de commencer, vous devez d\'abord choisir la ressource critique avec le formateur.',
    },
    feverchart: {
      title: 'Fever Chart Multi-projets',
      progress: 'Progression',
      buffer: 'Buffer',
      mono: 'Mono',
      multi: 'Multi',
    },
    automatic: {
      message: 'Votre projet est en mode automatique. Vous ne pouvez pas y accéder pour le moment.',
    },
  },
  multitask: {
    welcome: {
      multiTaskParallel: {
        explain: 'Dans cet exercice, vous devez réaliser des combinaisons composées de lettres, de nombres et de formes. L’ordre de sélection des formes est : carré, rond, triangle. Vous devez obtenir une suite de valeurs comme ceci : A 1 carré, B 2 cercle, C 3 triangle, etc…',
        videoInstructionUrl: '/videos/instruction-multitask-fr.mp4',
      },
      multiTaskSequential: {
        explain: 'Dans cet exercice, vous devez réaliser des combinaisons composées de lettres, de nombres et de formes. L’ordre de sélection des formes est : carré, rond, triangle. Cependant, vous allez cette fois-ci commencer par sélectionner toutes les lettres : A, B, C... puis tous les nombres : 1, 2, 3… puis toutes les formes : carré, cercle, triangle…',
        videoInstructionUrl: '/videos/instruction-monotask-fr.mp4',
      },
      title: 'Bienvenue',
      confirmMessage: 'Avez vous bien pris connaissance des consignes ? Après validation, le jeu va démarrer !',
      startGame: 'Commencer le jeu',
    },
    playing: {
      multiTaskParallel: {
        informations: 'Vous devez sélectionner la première lettre, puis le premier nombre, puis la première forme dans l\'ordre suivant (Carré, Cercle, Triangle)',
      },
      multiTaskSequential: {
        informations: 'Vous devez sélectionner toutes les lettres, puis tous les nombres, puis toutes les formes dans l\'ordre suivant (Carré, Cercle, Triangle)',
      },
      countdown: '| Le test commence dans {countdown} seconde | Le test commence dans {countdown} secondes',
      mistakeMessage: 'Vous avez fait une erreur',
      choiceList: 'Liste des éléments à sélectionner',
      resultList: 'Résultats',
      finished: 'Vous avez terminé, veuillez patienter...',
    },
    finished: {
      timePlayed: '| Vous avez fini en {timePlayed} seconde | Vous avez fini en {timePlayed} secondes',
      numberOfMistakes: 'et en faisant aucune erreur | et en faisant {numberOfMistakes} erreur | et en faisant {numberOfMistakes} erreurs',
    },
  },
  networkdiagram: {
    welcome: {
      networkDiagramWithoutInstructions: {
        explain: 'Dans cet exercice, vous devez réaliser une maison à partir des différentes formes numérotées. Pour cela, il vous suffit de déplacer les formes dans le gabarit jusqu’à l’obtention de la maison.',
        videoInstructionUrl: '/videos/networkDiagram-without-instructions.mp4',
      },
      networkDiagramWithInstructions: {
        explain: 'Dans cet exercice, vous devez réaliser une maison à partir des différentes formes numérotées. Pour cela, il vous suffit de déplacer les formes dans le gabarit jusqu’à l’obtention de la maison. Cependant, vous disposez cette fois des instructions nécessaires à la réalisation de la maison. Vous devez réaliser chaque instruction, puis passer à la suivante, jusqu’à la dernière où vous pourrez valider votre maison.',
        videoInstructionUrl: '/videos/networkDiagram-with-instructions.mp4',
      },
      title: 'Bienvenue',
      confirmMessage: 'Avez-vous bien pris connaissance des consignes ? Après validation, le jeu va démarrer !',
      startGame: 'Commencer le jeu',
    },
    playing: {
      nextInstruction: 'Instruction Suivante',
      validateConfirm: 'Voulez-vous terminer la partie et envoyer votre résultat au formateur ?',
    },
    finished: {
      noPlayed: 'La partie est terminée mais vous n\'avez pas lancé le jeu',
      timePlayed: 'Terminée en\n{seconds} secondes | Terminée en\n{minutes} minute et {seconds} secondes | Terminée en\n{minutes} minutes et {seconds} secondes',
    },
  },
  ontime: {
    waitNextQuestionButton: 'Réponse envoyée',
    waitNextQuestionMessage: 'Votre réponse a bien été envoyée, veuillez patienter...',
    gameFinishedMessage: 'Vous avez répondu à toutes les questions !',
    selectLabel: 'Votre réponse',
    dayBefore: 'La veille',
    choiceList: {
      '00:00': '00:00',
      '00:30': '00:30',
      '01:00': '01:00',
      '01:30': '01:30',
      '02:00': '02:00',
      '02:30': '02:30',
      '03:00': '03:00',
      '03:30': '03:30',
      '04:00': '04:00',
      '04:30': '04:30',
      '05:00': '05:00',
      '05:30': '05:30',
      '06:00': '06:00',
      '06:30': '06:30',
      '07:00': '07:00',
      '07:30': '07:30',
      '08:00': '08:00',
      '08:30': '08:30',
      '09:00': '09:00',
      '09:30': '09:30',
    },
  },
  criticalchain: {
    bufferNotDefined: 'A définir',
    welcome: {
      explain: 'Dans cet exercice, vous devez réaliser une maison à partir des différentes formes numérotées. Pour cela, il vous suffit de déplacer les formes dans le gabarit jusqu’à l’obtention de la maison. Cependant, vous disposez cette fois des instructions nécessaires à la réalisation de la maison. Vous devez réaliser chaque instruction, puis passer à la suivante, jusqu’à la dernière où vous pourrez valider votre maison.',
      title: 'Bienvenue',
      confirmMessage: 'Avez-vous bien pris connaissance des consignes ? Après validation, le jeu va démarrer !',
      startGame: 'Commencer le jeu',
    },
    steps: {
      CriticalChainRemoveMultitask: 'Multi-tâche',
      CriticalChainSelectCriticalChain: 'Chaîne critique',
      CriticalChainDefineBufferSize: 'Buffer',
    },
    removeMultitask: {
      message: 'Déplacer les tâches du gantt pour que les ressources n\'aient pas plusieurs tâches à réaliser en parallèles.',
    },
    selectCriticalChain: {
      message: 'Sélectionner sur le gantt, les tâches appartenant à la chaîne critique.',
    },
    defineBufferSize: {
      message: 'Définissez la taille du buffer en nombre de jours pour ce projet.',
      bufferSuffix: 'Jours',
    },
    finished: {
      message: 'Vous avez terminé !',
    },
  },
}
