export default [
  {
    path: '/criticalChain/:gameId',
    component: () => import('src/layouts/DefaultLayout'),
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        name: 'CriticalChain',
        component: () => import('./containers/CriticalChainContainer'),
        props: true,
        children: [
          {
            path: 'welcome',
            name: 'CriticalChainWelcome',
            component: () => import('./containers/WelcomeContainer'),
          },
          {
            path: 'playing',
            component: () => import('./containers/PlayingContainer'),
            children: [
              {
                path: 'remove-multitask',
                name: 'CriticalChainRemoveMultitask',
                component: () => import('./containers/RemoveMultitaskContainer'),
              },
              {
                path: 'select-critical-chain',
                name: 'CriticalChainSelectCriticalChain',
                component: () => import('./containers/SelectCriticalChainContainer'),
              },
              {
                path: 'define-buffer-size',
                name: 'CriticalChainDefineBufferSize',
                component: () => import('./containers/DefineBufferSizeContainer'),
              },
              {
                path: 'finished',
                name: 'CriticalChainFinished',
                component: () => import('./containers/FinishedContainer'),
              },
            ],
          },
        ],
      },
    ],
  },
]
