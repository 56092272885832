import i18n from 'src/plugins/i18n'
import Repositories from 'src/repositories'
import socket from 'src/services/socket'
import AxiosAuthenticationToken from 'src/services/axios/authentication/token'
import AxiosAuthenticationEvent from 'src/services/axios/authentication/event'

export async function init ({ commit, getters }) {
  if (getters.isAuthenticated) {
    AxiosAuthenticationToken.set(getters.tokens)
  }
  AxiosAuthenticationEvent.on('tokenRefreshSuccessful', tokens => {
    commit('SET_TOKENS', tokens)
  })
  AxiosAuthenticationEvent.on('tokenRefreshFailed', () => {
    commit('LOGOUT')
  })
}

export async function login ({ dispatch, commit, getters }, credentials) {
  try {
    const response = await Repositories.me.login(credentials)

    const { accessToken, refreshToken } = response.data
    AxiosAuthenticationToken.set({ accessToken, refreshToken })
    commit('LOGIN', response.data)
    dispatch('joinSession')

    const notification = i18n.t('general.welcome', { name: getters.fullname })
    dispatch('ui/showSuccessNotification', notification, { root: true })
    return true
  }
  catch (error) {
    const notification = Repositories.parseErrorMessage(error)
    dispatch('ui/showErrorNotification', notification, { root: true })
    console.error(error)
    return false
  }
}

export async function logout ({ dispatch, commit }) {
  try {
    await Repositories.me.logout()
  }
  catch (error) {
    const notification = Repositories.parseErrorMessage(error)
    dispatch('ui/showErrorNotification', notification, { root: true })
    console.error(error)
  }
  finally {
    // Ensure client logout, even if a server error has occured
    dispatch('leaveSession')
    commit('LOGOUT')
  }
}

export async function setLanguage ({ commit }, language) {
  commit('SET_LANGUAGE', language)
}

export function joinSession ({ commit, getters }) {
  if (getters.sessionId) {
    const data = { id: getters.sessionId }
    socket.emit('trainingSessionJoin', data, () => commit('SESSION_JOINED'))
  }
}

export function leaveSession ({ commit, getters }) {
  if (getters.sessionId) {
    const data = { id: getters.sessionId }
    socket.emit('trainingSessionLeave', data, () => commit('SESSION_LEFT'))
  }
}
