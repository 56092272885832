export function player (state) {
  return state.player
}

export function trainingSessionPlayerId (state, getters) {
  // TODO: Remove usage of playerCode
  return getters.player.playerId
}

export function sessionId (state) {
  return state.sessionId
}

export function fullname (state, getters) {
  return getters?.player?.playerName
}

export function tokens (state) {
  return state.tokens
}

export function isAuthenticated (state, getters) {
  return !!getters.player && !!getters.tokens
}

export function isConnectedToSession (state) {
  return state.isConnectedToSession
}

export function language (state) {
  return state?.language ?? 'en'
}
