import _keyBy from 'lodash/keyBy'
import _mapValues from 'lodash/mapValues'

export function session (state) {
  return state.session
}

export function sessionId (state, getters) {
  return getters.session?.id
}

export function sessionCode (state, getters) {
  return getters.session?.code
}

export function status (state, getters) {
  return getters.session.status
}

export function pause (state, getters) {
  return getters?.session?.pause
}

export function gameList (state, getters) {
  return getters?.session?.gameList ?? []
}

export function gameStatusListByResourceId (state, getters) {
  return _mapValues(_keyBy(getters.gameList, 'resourceId'), 'status')
}

export function gameNameListByResourceId (state, getters) {
  return _mapValues(_keyBy(getters.gameList, 'resourceId'), 'name')
}
