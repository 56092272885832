export default [
  {
    path: '/multiTask/:gameId',
    alias: [
      '/multiTaskParallel/:gameId',
      '/multiTaskSequential/:gameId',
    ],
    component: () => import('src/layouts/DefaultLayout'),
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        name: 'MultiTask',
        component: () => import('./containers/MultiTaskContainer'),
        props: true,
        children: [
          {
            path: 'welcome',
            name: 'MultiTaskWelcome',
            component: () => import('./containers/WelcomeContainer'),
          },
          {
            path: 'playing',
            name: 'MultiTaskPlaying',
            component: () => import('./containers/PlayingContainer'),
          },
          {
            path: 'finished',
            name: 'MultiTaskFinished',
            component: () => import('./containers/FinishedContainer'),
          },
        ],
      },
    ],
  },
]
