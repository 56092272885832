export function SHOW_NOTIFICATION (state, { success, message }) {
  state.notification = { visible: true, success, message }
}

export function HIDE_NOTIFICATION (state) {
  state.notification = {
    visible: false,
    message: '',
    success: state?.notification?.success,
  }
}

export function SET_TITLE (state, title) {
  state.title = title
}
