import Vue from 'vue'
import VueRouter from 'vue-router'
import AppModules from 'src/modules'
import store from 'src/store'
import { registerStoreModule } from './store-module'
import { createGuards } from './guards'

Vue.use(VueRouter)

const router = new VueRouter({
  routes: [...AppModules.getRoutes()],
})

createGuards(router)
registerStoreModule({ router, store })

export default router
