export function CONNECT (state) {
  state.isConnected = true
}

export function DISCONNECT (state) {
  state.isConnected = false
}

export function RECONNECT (state) {
  state.isConnected = true
}
