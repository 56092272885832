<template>
  <router-view />
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'App',
  computed: {
    ...mapGetters('me', {
      language: 'language',
      sessionId: 'sessionId',
    }),
  },
  watch: {
    language: {
      immediate: true,
      handler (value) {
        this.$i18n.locale = value
        this.$vuetify.lang.current = value
      },
    },
    sessionId (sessionId) {
      if (sessionId) {
        this.loadSession(sessionId)
      }
    },
  },
  async created () {
    await this.$store.dispatch('me/init')
    if (this.sessionId) {
      this.loadSession(this.sessionId)
    }
  },
  methods: {
    ...mapActions('session', {
      loadSession: 'loadSession',
    }),
  },
}
</script>
<style>
.justify-last-center {
  text-align: justify;
  text-align-last: center;
}
</style>
