import Vue from 'vue'
import Vuex from 'vuex'
import _pick from 'lodash/pick'
import _omit from 'lodash/omit'
import createPersistedState from 'vuex-persistedstate'
import AppModules from 'src/modules'
import createMediator from './mediator-plugin'

import modules from './modules'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    ...modules,
    ...AppModules.getStores(),
  },
  plugins: [
    createPersistedState({
      key: 'player-ccpm',
      paths: ['me'],
      reducer (state, paths) {
        return {
          ..._pick(state, paths),
          me: _omit(state.me, ['isConnectedToSession']),
        }
      },
    }),
    createMediator({
      listeners: [
        { on: 'me/LOGIN', dispatch: 'router/replace', payload: { name: 'Home' } },
        { on: 'me/LOGOUT', dispatch: 'session/resetState' },
        { on: 'me/LOGOUT', dispatch: 'router/replace', payload: { name: 'Login' } },
        { on: 'socket/CONNECT', dispatch: 'me/joinSession' },
        { on: 'socket/RECONNECT', dispatch: 'me/joinSession' },
        { on: 'socket/RECONNECT', dispatch: 'session/reloadSession' },
      ],
    }),
  ],
})
