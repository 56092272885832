import i18n from 'src/plugins/i18n'

export function SOCKET_trainingSessionPause ({ dispatch, commit }) {
  commit('PATCH_SESSION', { pause: true })
  const notification = i18n.t('notification.pause')
  dispatch('ui/showSuccessNotification', notification, { root: true })
}

export function SOCKET_trainingSessionPlay ({ dispatch, commit }) {
  commit('PATCH_SESSION', { pause: false })
  const notification = i18n.t('notification.play')
  dispatch('ui/showSuccessNotification', notification, { root: true })
}

export function SOCKET_trainingSessionGameOpened ({ dispatch, commit }, { id }) {
  commit('PATCH_GAME', { id, status: 'opened' })
  const notification = i18n.t('notification.gameOpened')
  dispatch('ui/showSuccessNotification', notification, { root: true })
}

export function SOCKET_trainingSessionGameFinished ({ dispatch, commit }, { id }) {
  commit('PATCH_GAME', { id, status: 'finished' })
  const notification = i18n.t('notification.gameFinished')
  dispatch('ui/showSuccessNotification', notification, { root: true })
}
