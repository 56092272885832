import RepositoryService from 'src/services/repository'

export default {
  sendAction: async ({
    gameId, playerId, actionName, actionData,
  }) => {
    const resourcePath = `/multiTask/session/${gameId}/player`
    const actionPath = `${resourcePath}/${playerId}/${actionName}`

    const response = await RepositoryService.base.post(actionPath, actionData)

    return response?.data ?? response
  },
  patch: async ({ gameId, playerId, ...data }) => {
    const resource = `/multiTask/session/${gameId}/player`
    return RepositoryService.crud.patch(resource, { id: playerId, ...data })
  },
}
