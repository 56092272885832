export default [
  {
    path: '/multiProjectStandard/:gameId',
    component: () => import('src/layouts/DefaultLayout'),
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        name: 'MultiProjectStandard',
        component: () => import('./containers/MultiProjectStandardContainer'),
        props: true,
        children: [
          {
            path: 'welcome',
            name: 'multiProjectStandardWelcome',
            component: () => import('./containers/WelcomeContainer'),
          },
          {
            path: 'in-progress',
            component: () => import('./containers/InProgressContainer'),
            children: [
              {
                path: 'start',
                name: 'multiProjectStandardStart',
                component: () => import('./containers/StartContainer'),
              },
              {
                path: 'resources-selection',
                name: 'multiProjectStandardResourcesSelection',
                component: () => import('./containers/ResourcesSelectionContainer'),
              },
              {
                path: 'resources-wait',
                name: 'multiProjectStandardWaitForRessource',
                component: () => import('./containers/ResourcesWaitContainer'),
              },
              {
                path: 'get-capacity',
                name: 'multiProjectStandardGetCapacity',
                component: () => import('./containers/GetCapacityContainer'),
              },
              {
                path: 'blue-over-capacity',
                name: 'multiProjectStandardBlueOverCapacity',
                component: () => import('./containers/BlueOverCapacityContainer'),
              },
              {
                path: 'capacity-received',
                name: 'multiProjectStandardCapacityReceived',
                component: () => import('./containers/CapacityReceivedContainer'),
              },
              {
                path: 'must-change-end-date',
                name: 'multiProjectStandardMustChangeEndDate',
                component: () => import('./containers/MustChangeEndDateContainer'),
              },
              {
                path: 'start-new-week',
                name: 'multiProjectStandardStartNewWeek',
                component: () => import('./containers/StartNewWeekContainer'),
              },
              {
                path: 'wait-new-week',
                name: 'multiProjectStandardWaitNewWeek',
                component: () => import('./containers/WaitNewWeekContainer'),
              },
              {
                path: 'project-finished',
                name: 'multiProjectStandardProjectFinished',
                component: () => import('./containers/ProjectFinishedContainer'),
              },
            ],
          },
          {
            path: 'automatic',
            name: 'multiProjectStandardAutomatic',
            component: () => import('./containers/AutomaticContainer'),
          },
          // Load parent container even if the child dosen't exists
          { path: '*' },
        ],
      },
    ],
  },
  {
    path: '/multiProjectCcpm/:gameId',
    component: () => import('src/layouts/DefaultLayout'),
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        name: 'MultiProjectCcpm',
        component: () => import('./containers/MultiProjectCcpmContainer'),
        props: true,
        children: [
          {
            path: 'waiting-critical-resource',
            name: 'multiProjectCcpmWaitingCriticalResource',
            component: () => import('./containers/WaitingCriticalResourceContainer'),
          },
          {
            path: 'welcome',
            name: 'multiProjectCcpmWelcome',
            component: () => import('./containers/WelcomeContainer'),
          },
          {
            path: 'in-progress',
            component: () => import('./containers/InProgressContainer'),
            children: [
              {
                path: 'start',
                name: 'multiProjectCcpmStart',
                component: () => import('./containers/StartCcpmContainer'),
              },
              {
                path: 'resources-selection',
                name: 'multiProjectCcpmResourcesSelection',
                component: () => import('./containers/ResourcesSelectionContainer'),
              },
              {
                path: 'resources-wait',
                name: 'multiProjectCcpmWaitForRessource',
                component: () => import('./containers/ResourcesWaitContainer'),
              },
              {
                path: 'get-capacity',
                name: 'multiProjectCcpmGetCapacity',
                component: () => import('./containers/GetCapacityContainer'),
              },
              {
                path: 'capacity-received',
                name: 'multiProjectCcpmCapacityReceived',
                component: () => import('./containers/CapacityReceivedContainer'),
              },
              {
                path: 'start-new-week',
                name: 'multiProjectCcpmStartNewWeek',
                component: () => import('./containers/StartNewWeekContainer'),
              },
              {
                path: 'wait-new-week',
                name: 'multiProjectCcpmWaitNewWeek',
                component: () => import('./containers/WaitNewWeekContainer'),
              },
              {
                path: 'project-finished',
                name: 'multiProjectCcpmProjectFinished',
                component: () => import('./containers/ProjectFinishedContainer'),
              },
            ],
          },
          {
            path: 'automatic',
            name: 'multiProjectCcpmAutomatic',
            component: () => import('./containers/AutomaticContainer'),
          },
          // Load parent container even if the child dosen't exists
          { path: '*' },
        ],
      },
    ],
  },
]
