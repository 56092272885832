import { set } from 'src/utils/vuex'

export const SET_USER = set('user')
export const SET_TOKENS = set('tokens')
export const SET_LANGUAGE = set('language')

export function LOGIN (state, payload) {
  const { accessToken, refreshToken } = payload
  state.tokens = { accessToken, refreshToken }
  state.player = payload.player
  state.sessionId = payload.trainingSession.id
  state.language = payload.trainingSession.lang
}

export function LOGOUT (state) {
  state.tokens = null
  state.player = null
  state.sessionId = null
}

export function SESSION_JOINED (state) {
  state.isConnectedToSession = true
}

export function SESSION_LEFT (state) {
  state.isConnectedToSession = true
}
