export default class Queue {
  constructor () {
    this.queue = []
  }

  async wait () {
    return new Promise((resolve, reject) => {
      this.queue.push({ resolve, reject })
    })
  }

  resolve (...args) {
    this.queue.map(({ resolve }) => resolve(...args))
    this.queue = []
  }

  reject (...args) {
    this.queue.map(({ reject }) => reject(...args))
    this.queue = []
  }
}
