export function SET_SESSION (state, session) {
  state.session = session
}

export function RESET_STATE (state) {
  state.session = null
}

export function PATCH_SESSION (state, updatedFields) {
  state.session = { ...state.session, ...updatedFields }
}

export function PATCH_GAME (state, { id, ...updatedFields }) {
  state.session.gameList = state.session.gameList
    .map(game => (game.id === id ? { ...game, ...updatedFields } : game))
}
