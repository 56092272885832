import i18n from 'src/plugins/i18n'

export function SOCKET_connect ({ commit }) {
  commit('CONNECT')
}

export function SOCKET_disconnect ({ dispatch, commit }) {
  commit('DISCONNECT')
  const notification = i18n.t('notification.socket.disconnect')
  dispatch('ui/showErrorNotification', notification, { root: true })
}

export function SOCKET_reconnect ({ dispatch, commit }) {
  commit('RECONNECT')
  const notification = i18n.t('notification.socket.reconnect')
  dispatch('ui/showSuccessNotification', notification, { root: true })
}
