import RepositoryService from 'src/services/repository'

export default {
  sendAction: async ({
    gameId, playerId, actionName, actionData,
  }) => {
    const resourcePath = `/onTime/session/${gameId}/player`
    const actionPath = `${resourcePath}/${playerId}/${actionName}`

    const response = await RepositoryService.base.post(actionPath, actionData)

    return response?.data ?? response
  },
}
