export default [
  {
    path: '/onTime/:gameId',
    component: () => import('src/layouts/DefaultLayout'),
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        name: 'OnTime',
        component: () => import('./containers/OnTimeContainer'),
        props: true,
        children: [
          {
            path: 'question-one',
            name: 'OnTimeQuestionOne',
            component: () => import('./containers/QuestionOneContainer'),
          },
          {
            path: 'question-two',
            name: 'OnTimeQuestionTwo',
            component: () => import('./containers/QuestionTwoContainer'),
          },
        ],
      },
    ],
  },
]
